import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import style from "../css/hero.module.css"

// importing images
import hostparty from "../images/blog/host.png"
import imageButton from "../images/blog/image-button.png"
import imageShare from "../images/blog/image-share.png"

const AboutQueue = () => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited About Queue")
    }
  }, [])

  return (
    <PageWrapper>
      <Wrapper>
        <Section>
          <HeaderContainer>
            <Header>What is Queue?</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            The idea for Queue started in the beginning of spring 2020, when we
            were literally getting bored to death.
            <br />
            <br />
            We missed attending big parties and meeting new cool people. This
            wasn’t something the other ‘social medias’ could fix, so we decided
            to make one that could👉 And the result was Queue.
            <br />
            <br />
            The Queue app has a feed that's only open on weekends, where you can
            share and view party photos within a radius of 30 km📸
            <br />
            <br />
            Using Queue you can therefore join your local nightlife and meet new
            people near you - even though corona has cancelled all the big
            parties.
            <br />
            <br />
            So join our waitlist now and make sure you get early access to the
            hottest social media right now👀
          </Paragraph>
          <StyledLink to="/">
            <Button>Join Waitlist</Button>
          </StyledLink>
        </Section>
      </Wrapper>
    </PageWrapper>
  )
}

export default AboutQueue

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.7rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Teaser = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1.2;

  margin-bottom: 3rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
  @media (min-width: 750px) {
    font-size: 1.8rem;
  }
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  min-height: 300px;

  height: auto;

  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`

const Image = styled.div`
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 500px) {
    width: 280px;
    height: 280px;
  }

  @media (min-width: 650px) {
    width: 320px;
    height: 320px;
  }

  @media (min-width: 750px) {
    width: 370px;
    height: 370px;
  }
`

// -------------

// Images extended from Image
const Image1 = styled(Image)`
  background-image: url(${hostparty});
`

const Image2 = styled(Image)`
  background-image: url(${imageButton});
`

const Image3 = styled(Image)`
  background-image: url(${imageShare});
`

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  line-height: 1.3;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 150px;
  height: 40px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 200px;
    height: 45px;
  }

  @media (min-width: 650px) {
    width: 250px;
    height: 50px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`
